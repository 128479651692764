export default {
  "appHeader": {
    "cadastrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录/注册"])},
    "registro-musicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["唱片"])},
    "ingressos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["门票"])},
    "conectar-carteira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接钱包"])},
    "colecionaveis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的收藏"])},
    "minhas-musicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的音乐"])},
    "sair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])}
  },
  "appFooter": {
    "direitos-reservados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["版权所有 ®"])},
    "politica-privacidade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐私政策"])},
    "termos-uso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条款和条件"])},
    "politica-cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie 政策"])},
    "ajuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你需要帮助吗"])}
  },
  "banner": {
    "carousel-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看收藏"])}
  },
  "cardHighlight": {
    "destaque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["强调"])},
    "veja-colecao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看收藏"])}
  },
  "cardAsset": {
    "esgotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["售罄"])},
    "preco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个中有"])},
    "disponivel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个可用"])},
    "disponivel-l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个可用"])},
    "disponivel-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个可用"])},
    "compre-agora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即购买"])},
    "visualizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["看法"])}
  },
  "assetsList": {
    "veja-colecao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看收藏"])}
  },
  "collectionDetails": {
    "usuario-nao-encontrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到用户"])},
    "erro404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误 404"])},
    "sobre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于"])}
  },
  "assetDetails": {
    "voltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背部"])},
    "esgotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["售罄"])},
    "disponivel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用:"])},
    "detalhes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["细节"])},
    "atributos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["属性"])},
    "compradores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买家"])},
    "data-criacao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期: "])},
    "proprietario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拥有者: "])},
    "sobre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于: "])},
    "ativo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产: "])},
    "sem-compradores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前还没有买家"])},
    "carregando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载..."])},
    "arquivo-adicional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加文件"])},
    "visualizar-arquivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看文件"])},
    "preco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
    "comprar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买"])},
    "ou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["或者"])},
    "pagar-pix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用 PIX 付款（仅限巴西）"])},
    "pagar-cartao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用信用卡支付"])},
    "conectar-carteira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["连接钱包"])},
    "lancamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布时间"])},
    "necessario-carteira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在您购买之前，有必要连接您的钱包。"])},
    "precisa-ajuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要帮忙？"])},
    "taxa-conveniencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对于 PIX 付款，将收取少量手续费。 PIX 仅适用于巴西"])},
    "prova-autenticidade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["真实性证明"])},
    "ver-ipfs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["见 IPFS"])}
  },
  "login": {
    "bemVindo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎来到 Música Protegida"])},
    "nao-tem-conta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还没有帐户？"])},
    "cadastrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登记"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
    "esqueci-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码"])}
  },
  "signUp": {
    "bemVindo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎来到 Música Protegida"])},
    "ja-tem-conta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已经有一个帐户？"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
    "nome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
    "sobrenome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓"])},
    "usuario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
    "senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
    "repetir-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复你的密码"])},
    "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF (仅限巴西)"])},
    "celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
    "cadastrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在注册"])}
  },
  "userDetails": {
    "agrupar-compras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["团购"])},
    "editar-perfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑个人资料"])},
    "nao-ha-compras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["还没有要显示的购买。"])}
  },
  "profile": {
    "informacoes-pessoais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人信息"])},
    "primeiro-nome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
    "ultimo-nome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名"])},
    "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF (仅限巴西)"])},
    "celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
    "sobre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关于"])},
    "redes-sociais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社交媒体"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
    "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
    "esconder-colecao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏收藏"])},
    "trocar-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改密码"])},
    "atualizar-perfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新个人信息"])},
    "fotoPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人資料圖片"])},
    "enviarImagem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送图片"])},
    "alterarImagem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["改变形象"])}
  },
  "changePassword": {
    "alterar-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改密码"])},
    "senha-atual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前密码"])},
    "nova-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码"])},
    "repita-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复你的新密码"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码修改成功"])},
    "voltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背部"])},
    "campo-vazio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此字段不能为空"])}
  },
  "forgotPassword": {
    "esqueceu-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码?"])},
    "resetar-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重设密码"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
    "voltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背部"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
    "aguarde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请稍等..."])}
  },
  "filterNft": {
    "carregando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载中..."])},
    "sem-categorias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无该分类记录！"])}
  },
  "musicAssetFilter": {
    "musica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歌曲"])},
    "musicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歌曲"])}
  },
  "assetTicketAddress": {
    "endereco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "chegar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["怎么获得"])}
  },
  "assetTicketPayment": {
    "pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付"])}
  },
  "modalCreditCardPayment": {
    "preco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
    "insira-dados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["插入您的详细信息"])},
    "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF (仅限巴西)"])},
    "contato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接触"])},
    "num-credit-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡号（VISA 或 MASTER）"])},
    "nome-titular-cartao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持卡人名字"])},
    "digite-nome-cartao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入名片上的名字"])},
    "validade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效性"])},
    "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部的"])},
    "confirmar-pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认付款！"])},
    "processando-pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正在处理付款！"])},
    "em-instantes-pag-confirmado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["几分钟后您的付款将得到确认，您的机票将显示在您的个人资料中！"])},
    "campo-obrigatorio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请填写此字段！"])}
  }
}
<template>
  <div id="principal">
    
    <div id="ichello" :class="{ 'auth' : ['Login', 'SignUp', 'VerifyEmail', 'ForgotPassword', 'ResetPassword'].includes($route.name) }">
        <div class="principal-header"><app-header /></div>
        <router-view />
    </div>
    <app-footer />
  </div> 
</template>

<script>
import AppFooter from '@/components/layout/AppFooter'
import AppHeader from '@/components/layout/AppHeader'

export default {
  components: { AppHeader, AppFooter },
}
</script>

<style>
@media print
{

    * {
      -webkit-print-color-adjust:exact;
      }
    
    ::-webkit-scrollbar {
        display: none;
    }

    .principal-header {
        visibility: hidden !important;
        display: none;
    }
}
</style>

<style scoped>
#principal { 
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
#ichello {
  --c-primary: #0d0d18;
  --c-primaryDark: #3B3B3B;
  --c-primaryLight: rgba(73, 67, 58, 0.2);
  --c-yellow: #feb23a;
  --c-red: #FA2773;
  --c-purple: #672DF8;
  --c-green: rgba(254, 178, 58, 0.2);
  --c-contrast: #01E769;
  --g-primary: linear-gradient(45deg, #2b8151, #4faf79);
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  flex: 1 0 auto;
}

#ichello:not(.auth) {
  max-width: 1320px;
}

.principal-header { 
  max-width: 1320px;
  margin: 0 auto;
}

</style>

<template>
    <header class="computer_header_link">
        <div class="header-bg" :class="{ 'active': menuActive }"></div>
        <div class="w-full header_new_links ">
            <div>
                <router-link to="/" @click="menuActive = false">
                    <img :src="require('@/assets/logo-white.png')" alt="" class="block mx-auto" width="160">
                </router-link>
            </div>
            
            <!-- <search-box /> -->
            <div v-if="!user" class="menu_links flex items-center gap-x-4">
                <SelectLanguage/>
                <!-- <router-link @click="menuActive = false" to="/login" class="inline-block header-link">MERCADO</router-link>
                <router-link @click="menuActive = false" to="/login" class="inline-block header-link">RESGATAR</router-link>
                <router-link @click="menuActive = false" to="/login" class="inline-block header-link">SOBRE</router-link>
                <router-link @click="menuActive = false" to="/login" class="inline-block header-link">AJUDA</router-link> -->
                <router-link  @click="menuActive = false" to="/login" class="inline-block header-link">{{ $t("appHeader.cadastrar") }}</router-link>
                <!-- <router-link @click="menuActive = false" to="/login" class="inline-block header-link px-3 py-2 bg-button rounded-4xl text-text_button font-bold hover:bg-transparent border-2 border-solid border-button hover:text-button">Conectar Carteira</router-link>  -->
            </div>
            <div v-else class="menu_links flex justify-between items-center gap-x-4">
                <router-link @click="menuActive = false" to="/my-musics" class="inline-block header-link">{{ $t("appHeader.minhas-musicas") }}</router-link>
                <router-link @click="menuActive = false" to="/nova" class="inline-block header-link">{{ $t("appHeader.registro-musicas") }}</router-link>
                <router-link @click="menuActive = false" to="/profile" class="inline-block header-link">{{ $t("appHeader.profile") }}</router-link>
                <SelectLanguage/>
                <!-- <router-link @click="menuActive = false" :to="`/collection/${user.username}`" class="inline-block px-3 py-2 bg-button rounded-4xl text-text_button font-bold hover:bg-transparent border-2 border-solid border-button hover:text-button">{{ $t("appHeader.colecionaveis") }}</router-link>

                <router-link @click="menuActive = false" :to="`/collection/${user.username}`" class="inline-block header-link">{{ user.first_name }} {{ user.last_name }}</router-link> -->
                <!-- <router-link @click="menuActive = false" :to="'/profile'" > -->
                    <ProfilePicture :mobile="false" :sair="eventLogout" :user="user" @conectarCarteira="prepareWlogin" :walletAddress="walletAddress"/>
                <!-- <button @click="prepareWlogin" class="inline-block header-link px-3 py-2 bg-button rounded-4xl text-text_button font-bold hover:bg-transparent border-2 border-solid border-button hover:text-button">Conectar Carteira</button>  -->
                <!-- </router-link> -->

                <!-- <button @click="eventLogout" class="inline-block header-link" >{{ $t("appHeader.sair") }}</button> -->
            </div>
             
        </div>
       
        <div class="modal-main"  v-if="renderLoginService && showLoginService"></div>
          <div class="modal__content  bg-dark" v-if="renderLoginService && showLoginService" >
            <div class="flex justify-between items-center px-4 pt-8 text-center">
                <h2 class="text-sm font-bold text-white">Para comprar na Ichello é necessário conectar uma carteira</h2>
                <span class="text-primary text-3xl cursor-pointer font-bold" @click="showLoginService = false">&times;</span>
            </div>
            <hr style="border: 0.5px solid white; margin: 16px 27px;">

            <h4 class="text-white font-bold text-xl text-center">Selecione um provedor de carteira</h4>

            <div class="flex gap-3 flex-col text-black my-6 px-6">
                <button class="bg-white flex gap-4 rounded-xl px-4 py-2" @click="showLoginService = false; walletLoginService = 'metamask'; wlogin();">
                    <img :src="require('@/assets/metamask.png')" alt="" class="block mx-auto" width="50">
                    <div class="flex-1 text-left">
                        <span class="font-bold text-lg">Conectar Metamask</span>
                        <p class="text-sm font-bold">Suporta pagamento cripto</p>
                    </div>
                </button>
                <button class="bg-white flex gap-4 rounded-xl px-4 py-2">
                    <img :src="require('@/assets/walletconnect.jpg')" alt="" class="block mx-auto" width="50" @click="showLoginService = false; walletLoginService = 'walletconnect'; wlogin();">
                    <div class="flex-1 text-left">
                        <span class="font-bold text-lg">Conectar WalletConnect</span>
                        <p class="text-sm font-bold">Suporta pagamento cripto</p>
                    </div>
                </button>
            </div>
        </div>
    </header>


    <div class="mobile_header_link">
        <div class="w-full flex justify-between items-center px-3">
            <div >
                <router-link to="/" @click="menuActive = false">
                    <img :src="require('@/assets/logo-white.png')" alt="" class="block mx-auto" width="160">
                </router-link>
            </div>
            <!-- aparece qnd tiver no mobile -->
            <SelectLanguage/>
            <!-- <div  @click="showMobileVersion = !showMobileVersion">
              
                <i style="font-size:36px" class="text-button fa fa-solid fa-bars"></i>	

            </div> -->
            <div class="menu-wrapper" @click="showMobileVersion = !showMobileVersion" :class="{ active: showMobileVersion }" >
                <div class="menu-bar one bg-button"></div>
                <div class="menu-bar two bg-button"></div>
            </div>
        </div>
       
            <div v-show="showMobileVersion" class="menu-mobile-style" @click="closeMenu()" >
            </div> 
            <app-mobile-header v-show="showMobileVersion" @logout="eventLogout" :user="user" @close="closeMenu" @conectarCarteira="prepareWlogin" :walletAddress="walletAddress" />
        
                <div class="modal-main"  v-if="renderLoginService && showLoginService"></div>
          <div class="modal__content  bg-dark" v-if="renderLoginService && showLoginService" >
            <div class="flex justify-between items-center px-4 pt-8 text-center">
                <h2 class="text-sm font-bold text-white">Para comprar na Ichello é necessário conectar uma carteira</h2>
                <span class="text-primary text-3xl cursor-pointer font-bold" @click="showLoginService = false">&times;</span>
            </div>
            <hr style="border: 0.5px solid white; margin: 16px 27px;">

            <h4 class="text-white font-bold text-xl text-center">Selecione um provedor de carteira</h4>

            <div class="flex gap-3 flex-col text-black my-6 px-6">
                <button class="bg-white flex gap-4 rounded-xl px-4 py-2" @click="showLoginService = false; walletLoginService = 'metamask'; wlogin();">
                    <img :src="require('@/assets/metamask.png')" alt="" class="block mx-auto" width="50">
                    <div class="flex-1 text-left">
                        <span class="font-bold text-lg">Conectar Metamask</span>
                        <p class="text-sm font-bold">Suporta pagamento cripto</p>
                    </div>
                </button>
                <button class="bg-white flex gap-4 rounded-xl px-4 py-2">
                    <img :src="require('@/assets/walletconnect.jpg')" alt="" class="block mx-auto" width="50" @click="showLoginService = false; walletLoginService = 'walletconnect'; wlogin();">
                    <div class="flex-1 text-left">
                        <span class="font-bold text-lg">Conectar WalletConnect</span>
                        <p class="text-sm font-bold">Suporta pagamento cripto</p>
                    </div>
                </button>
            </div>
        </div>

    </div>
</template>

<script>
import Moralis from 'moralis'
import router from '@/router';
import { mapActions, mapGetters } from 'vuex'
import AppMobileHeader from './AppMobileHeader.vue'
import SelectLanguage from './SelectLanguage.vue'
import ProfilePicture from './Profile-Picture.vue'

//import SearchBox from '../shared/SearchBox.vue'
//import CookiesDialogVue from '../CookiesDialog.vue'


export default {
    data() {
        return {
            searchActive: false,
            menuActive: false,
            blockNumber: 0,
            walletLoginService: 'walletconnect',
            renderLoginService: typeof window.ethereum !== 'undefined',
            showLoginService: false,
            showModalLogin: false,
            showMobileVersion: false,
            walletAddress: false,
        }
    },
    components: {
        AppMobileHeader,
        SelectLanguage,
        ProfilePicture,
    },
    computed: {
        ...mapGetters({
            user: 'user',
            wallet: 'wallet',
        }),
    },
    async created() {
        this.connect();
        this.fetchUser();
    },
    methods: {
        ...mapActions(['doLogout', 'fetchUser', 'setWallet']),
        closeMenu() {
            this.showMobileVersion = !this.showMobileVersion;
        },
        eventLogout() {
            this.doLogout()
            localStorage.removeItem('wallet')
            this.setWallet(false)
            router.push({ name: 'Login' })
        },
        logout() {
            this.doLogout()
            localStorage.removeItem('wallet')
            this.setWallet(false)
            router.push({ name: 'Login' })
        },
        connect() {
            const serverUrl = process.env.VUE_APP_MORALIS_URL
            const appId = process.env.VUE_APP_MORALIS_ID

            Moralis.start({serverUrl, appId})
        },
        
        // add from here down
        async wlogin() {
             let user = await Moralis.User.current();
            if (!user) {
                user = await Moralis.authenticate({
                  provider: this.walletLoginService,
                  chainId: 56,
                  signingMessage:"Música Protegida"
                });
            }
            let wallets = user.get('ethAddress');
            this.walletAddress = wallets;
            this.setWallet(wallets)
        },
        walletConnect() { 
            this.menuActive = false
            this.prepareWlogin()
        },
    prepareWlogin() {
            if (this.renderLoginService) {
                this.showLoginService = true;
            } else {
                this.wlogin();
            }
        },

        async wlogOut() {
            await Moralis.User.logOut();
            console.log("logged out");
            this.setWallet(false)
        },
    },
}
</script>

<style>
.modal-main {
  font-family: sans-serif;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; 
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal__content{ 
    width: 30em;
    border-radius: 5px;
    background:  #202020;
    position: fixed;
    z-index: 50;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5em 1em 1em 1em;
}

.menu-mobile-style { 
    width: 100vw;
    height: 100vh;
   
    position: absolute;
    top: 0;
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    z-index: 40;
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-25px);
  opacity: 0;
}
.header_new_links { 
   /*  para depois */
   /*  display: grid;
    padding: 2rem 0;
    grid-template-columns: 160px 1fr 3fr;
    align-items: center;
    column-gap: 3.2rem; */

    /* temporario */
    display: flex;
    padding: 2rem 0;
    justify-content: space-between;
    align-items: center;
    column-gap: 3.2rem;
    
}
.header__1 {
    background: #191919;
    color: white;
    border: none;
}

.header__1 , .header__1 .wrapper{
    padding: 0;
}

.header__logo img {
    height: 70px;
    object-fit: cover;
    width: 180px;
}

.header__search {
    background: #444444;
}

.header__result {
    background: #7a7a7a;
}

.header-link {
    text-transform: uppercase;
    color: #fff;
} 

@media screen and (min-width: 1025px)  {
     .mobile_header_link { 
        display: none;
    }
    .computer_header_link { 
        display: block;
        
    }
    .search {
        all: unset;
    }
    .header_mobile_links { 
        display: none;
    }
}
@media screen and (max-width: 1024px)  {
    .mobile_header_link { 
        display: block;
        padding-top: 1.5rem/* 24px */;
        padding-bottom: 1.5rem/* 24px */;
        display: flex;
        row-gap: 1rem;
        flex-direction: column;
    }
    .computer_header_link { 
        display: none;
    }
    .search {
        display: none;
    }
    .header_new_links { 
      
        display: grid;
        grid-template-columns: 100px 100px;
        
    }
     .header_new_links .menu_links {
   display: none;
}
    
}
@media screen and (max-width: 768px) {
   
    .header__1 {
        height: 56px;
        display: flex;
        align-items: center;
    }

    .logo {
        margin-left: -15px;
    }

    .header__mobile.active {
        visibility: visible;
        z-index: 1000;
        opacity: 1;
        border-radius: 0;
        background: #191919 !important;
    }
}

.wallet {
    border: 2px solid #01E769;
    border-radius: 20px;
    padding: 4px 12px;
    position: relative;
}

.wallet:hover:after {
    opacity: 1;
}

.wallet:after {
    content: 'Desconectar';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background: red;
    left: 0;
    top: 0;
    transition: .3s;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}


.modal-content {
    width: 450px;
    height: 300px;
    max-width: calc(100vw - 20px);
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, -50%);
}

.header-bg {
    opacity: 0;
    pointer-events: none;
}

@media screen and (max-width: 768px) {
    .modal__content{ 
        top: 25%;
    }

    .header-bg {
        background-color: rgba(0,0,0,0.50);
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 0;
        transition: all .6s;
    }

    .header-bg.active {
        opacity: 1;
        pointer-events: auto;
    }
}

/* button mobile style */
.menu-wrapper {
  width: 40px;
  height: auto;
  cursor: pointer;
  position: relative;
}

.menu-bar {
  position: absolute;
  width: 100%;
  height: 4px;
  background: #fff;
  left: 0%;
}

/*.menu-wrapper:hover .menu-bar {
  animation-duration: 1s;
  animation-name: slideOut;
  animation-direction: alternate;
  animation-timing-function: ease-out;
}*/



.one {
  top: 0px;
  animation-delay: 0.1s;
  transition: all 0.3s;
}

.two {
  top: 8px;
  transition: all 0.3s;
}


@keyframes slideOut {
  0% {
    width: 100%;
    left: 0%;
    right: auto;
  }
  
  50% {
    width: 0%;
    left: 0%;
    right: auto;
  }
  51% {
    width: 0%;
    right: 0%;
    left: auto;
  }

  100% {
    width: 100%;
    right: 0%;
    left: auto;
  }
}




/**/

.menu-wrapper:hover .menu-bar.active {
  animation:none;
}
.active .one{
  top: 50%;
  left: 0%;
  transform: rotate(45deg);
}
.active .two{
  top: 50%;
  left: 0%;
  transform: rotate(-45deg);
}



</style>

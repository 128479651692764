export default {
  "appHeader": {
    "cadastrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTRAR/CADASTRE-SE"])},
    "registro-musicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova Música"])},
    "ingressos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingressos"])},
    "conectar-carteira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar Carteira"])},
    "colecionaveis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meus Colecionáveis"])},
    "minhas-musicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minhas Músicas"])},
    "sair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sair"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])}
  },
  "appFooter": {
    "direitos-reservados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos os direitos reservados ®"])},
    "politica-privacidade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidade"])},
    "termos-uso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termos de uso"])},
    "politica-cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de cookies"])},
    "ajuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajuda?"])}
  },
  "banner": {
    "carousel-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veja a coleção"])}
  },
  "cardHighlight": {
    "destaque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DESTAQUE"])},
    "veja-colecao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veja a coleção"])}
  },
  "cardAsset": {
    "esgotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esgotado"])},
    "preco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["preço"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
    "disponivel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponíve"])},
    "disponivel-l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponível"])},
    "disponivel-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponíveis"])},
    "compre-agora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compre agora"])},
    "visualizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizar"])}
  },
  "assetsList": {
    "veja-colecao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veja a coleção"])}
  },
  "collectionDetails": {
    "usuario-nao-encontrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário não encontrado!"])},
    "erro404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro 404"])},
    "sobre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre"])}
  },
  "assetDetails": {
    "voltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
    "esgotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esgotado"])},
    "disponivel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponíveis:"])},
    "detalhes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalhes"])},
    "atributos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atributos"])},
    "compradores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compradores"])},
    "data-criacao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de Criação: "])},
    "proprietario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietário: "])},
    "sobre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre: "])},
    "ativo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATIVO: "])},
    "sem-compradores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há nenhum comprador até o momento"])},
    "carregando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando..."])},
    "arquivo-adicional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arquivo Adicional"])},
    "visualizar-arquivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizar Arquivo"])},
    "preco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço"])},
    "comprar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "ou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
    "pagar-pix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar com PIX"])},
    "pagar-cartao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagar com Cartão de Crédito"])},
    "conectar-carteira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar carteira"])},
    "lancamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lançamento em"])},
    "necessario-carteira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para comprar é necessário conectar sua carteira."])},
    "precisa-ajuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precisa de ajuda?"])},
    "taxa-conveniencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para pagamentos com PIX, haverá uma pequena taxa de conveniência."])},
    "prova-autenticidade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prova de autenticidade"])},
    "ver-ipfs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver IPFS"])}
  },
  "login": {
    "bemVindo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bem-vindo a Música Protegida"])},
    "nao-tem-conta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainda não tem uma conta?"])},
    "cadastrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadastre-se"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
    "esqueci-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueci a Senha"])}
  },
  "signUp": {
    "bemVindo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bem-vindo a Música Protegida"])},
    "ja-tem-conta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Já tem uma conta?"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrar"])},
    "nome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "sobrenome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobrenome"])},
    "usuario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuário"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
    "repetir-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repita a senha"])},
    "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF"])},
    "celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celular"])},
    "cadastrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadastrar"])}
  },
  "userDetails": {
    "agrupar-compras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrupar Compras"])},
    "editar-perfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar Perfil"])},
    "nao-ha-compras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ainda não há compras para mostrar."])}
  },
  "profile": {
    "informacoes-pessoais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informações Pessoais"])},
    "primeiro-nome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primeiro nome"])},
    "ultimo-nome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último nome"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF"])},
    "celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celular"])},
    "sobre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre"])},
    "redes-sociais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
    "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
    "esconder-colecao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esconder Coleção"])},
    "trocar-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trocar senha"])},
    "atualizar-perfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atualizar Perfil"])},
    "fotoPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto de perfil"])},
    "enviarImagem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar Imagem"])},
    "alterarImagem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar Imagem"])}
  },
  "changePassword": {
    "alterar-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alterar Senha"])},
    "senha-atual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha atual"])},
    "nova-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova senha"])},
    "repita-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repita a Senha"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senha alterada com sucesso!"])},
    "voltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
    "campo-vazio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este campo não pode ficar vazio!"])}
  },
  "forgotPassword": {
    "esqueceu-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esqueceu a Senha?"])},
    "resetar-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetar Senha"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "voltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voltar"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "aguarde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aguarde..."])}
  },
  "filterNft": {
    "carregando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carregando..."])},
    "sem-categorias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Não há registros desta categoria no momento!"])}
  },
  "musicAssetFilter": {
    "musica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Música"])},
    "musicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Músicas"])}
  },
  "assetTicketAddress": {
    "endereco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endereço"])},
    "chegar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Como chegar"])}
  },
  "assetTicketPayment": {
    "pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento"])}
  },
  "modalCreditCardPayment": {
    "preco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preço"])},
    "insira-dados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INSIRA SEUS DADOS"])},
    "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF"])},
    "contato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTATO"])},
    "num-credit-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO DO CARTÃO (VISA OU MASTER)"])},
    "nome-titular-cartao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOME DO TITULAR DO CARTÃO"])},
    "digite-nome-cartao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digite o nome no cartão"])},
    "validade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALIDADE"])},
    "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "confirmar-pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar Pagamento!"])},
    "processando-pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento está sendo processado!"])},
    "em-instantes-pag-confirmado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Em minutos seu pagamento será confirmado e seu ingresso estará disponível no seu perfil!"])},
    "campo-obrigatorio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, preencha este campo!"])}
  }
}
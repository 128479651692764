<!--  eslint-disable  -->

<template>
    <footer class="bg-black text-white py-5 flex-shrink-0">
        <div class="footers py-12">
            <div class="flex flex-wrap flex-col md:flex-row justify-between items-center space-y-5 md:space-y-0">
                <div class="footerImage ">
                    <div class="flex justify-center"><svg width="160"  viewBox="0 0 135 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M47.0811 28.2344C46.4153 28.13 45.7494 28.0777 45.0967 27.9341C41.18 27.0986 38.5428 24.2786 37.9422 20.3097C37.655 18.4166 37.7072 16.5236 38.2817 14.6827C39.3522 11.3144 41.7022 9.31689 45.1097 8.55967C47.3031 8.07661 49.4964 8.10272 51.6114 9.00356C52.7211 9.47356 53.6742 10.1916 54.5489 11.0663C53.5697 12.0977 52.6036 13.1291 51.6375 14.1474C48.8697 11.7844 45.3708 12.5547 43.7519 14.4608C41.9894 16.5366 42.0025 20.1269 43.8042 22.1244C45.6711 24.2133 49.7445 24.3308 51.9117 22.2027C52.4208 22.6988 52.9431 23.1949 53.4522 23.7041C53.9483 24.2133 54.4314 24.7224 54.9536 25.2577C54.9406 25.2838 54.8883 25.3491 54.8361 25.4144C53.1258 27.0463 51.1283 28.0255 48.7392 28.1691C48.6478 28.1691 48.5433 28.2083 48.452 28.2213C47.982 28.2344 47.525 28.2344 47.0811 28.2344Z" fill="#FEFEFE"/>
<path d="M32.7069 0.765503C32.8636 0.80467 33.0203 0.843836 33.1639 0.883002C34.6 1.27467 35.3703 2.61939 35.0308 4.15995C34.7305 5.54384 33.4119 6.43162 31.9628 6.20967C30.605 6.00078 29.6911 5.06078 29.6258 3.79439C29.5475 2.30606 30.2525 1.24856 31.5581 0.896059C31.7147 0.856892 31.8714 0.817725 32.015 0.778558C32.2631 0.765503 32.485 0.765503 32.7069 0.765503Z" fill="#FEFEFE"/>
<path d="M57.0686 0.92217C58.6091 0.92217 60.1367 0.92217 61.6903 0.92217C61.6903 4.36884 61.6903 7.80245 61.6903 11.3405C61.8339 11.1708 61.9253 11.0924 62.0036 11.0011C63.3614 9.42134 65.0847 8.638 67.1475 8.55967C68.3225 8.5205 69.4975 8.62495 70.6072 9.04273C72.7744 9.83912 74.0278 11.4449 74.7067 13.5861C75.1375 14.9308 75.268 16.3147 75.268 17.7247C75.2811 20.9102 75.268 24.0827 75.268 27.2683C75.268 27.3858 75.268 27.5163 75.268 27.6469C73.7014 27.6469 72.1869 27.6469 70.6072 27.6469C70.6072 27.4772 70.6072 27.3205 70.6072 27.1638C70.6072 23.9783 70.6203 20.8058 70.5811 17.6202C70.568 16.8761 70.4375 16.0927 70.2025 15.3877C69.6803 13.8341 68.4922 13.0638 66.8733 12.9072C63.7661 12.6199 61.9644 14.9961 61.7294 17.3461C61.6903 17.7116 61.6772 18.0902 61.6772 18.4688C61.6772 21.3672 61.6772 24.2524 61.6772 27.1508C61.6772 27.2944 61.6772 27.4511 61.6772 27.6338C60.1366 27.6338 58.6091 27.6338 57.0425 27.6338C57.0686 18.73 57.0686 9.83912 57.0686 0.92217Z" fill="#FEFEFE"/>
<path d="M96.6792 25.1794C95.5825 26.2891 94.2117 27.0333 92.6711 27.5163C89.9555 28.365 87.24 28.4172 84.5505 27.4902C81.2605 26.3544 79.3153 24.0044 78.6103 20.623C78.1533 18.4036 78.2316 16.1972 79.0803 14.0691C80.3858 10.8183 82.8664 8.9905 86.2739 8.38995C88.0755 8.07661 89.8772 8.14189 91.6528 8.638C94.9558 9.56495 96.875 11.7974 97.6061 15.0874C97.9586 16.6411 97.9847 18.2208 97.815 19.8658C92.9453 19.8658 88.0625 19.8658 83.1536 19.8658C83.3103 21.0147 83.8194 21.9155 84.668 22.6205C85.608 23.3908 86.7178 23.7433 87.9058 23.8477C89.6944 24.0044 91.3786 23.6649 92.9453 22.7641C93.2325 22.5944 93.4936 22.3724 93.7808 22.1766C94.7339 23.1688 95.7 24.1611 96.6792 25.1794ZM93.363 16.0144C93.1672 14.6305 92.5275 13.5599 91.2872 12.9855C90.6475 12.6852 89.9164 12.5024 89.2114 12.4111C87.6447 12.2283 86.1564 12.5155 84.8639 13.5077C84.0414 14.1474 83.5322 14.9961 83.245 16.0144C86.6264 16.0144 89.9686 16.0144 93.363 16.0144Z" fill="#FEFEFE"/>
<path d="M10.147 13.5338C10.9564 13.5338 11.7006 13.5338 12.497 13.5338C12.497 13.3902 12.497 13.2466 12.497 13.1161C12.497 11.6147 12.4839 10.1263 12.497 8.62495C12.51 7.69801 13.202 6.91467 14.0767 6.82328C15.0559 6.71884 15.8784 7.26717 16.1134 8.19412C16.1525 8.33773 16.1656 8.4944 16.1656 8.63801C16.1656 13.1813 16.1656 17.7247 16.1656 22.255C16.1656 23.1558 15.5781 23.8608 14.7556 24.0436C13.8809 24.2263 13.0192 23.8347 12.6797 23.0383C12.5622 22.7641 12.51 22.4377 12.497 22.1375C12.4839 20.5969 12.4839 19.0694 12.4839 17.5288C12.4839 17.4375 12.4709 17.3591 12.4709 17.2547C11.7006 17.2547 10.9434 17.2547 10.1339 17.2547C10.1339 17.4113 10.1339 17.568 10.1339 17.7247C10.1209 18.4558 10.16 19.2 10.0686 19.9311C9.95113 20.8188 9.11558 21.4063 8.16252 21.3672C7.2878 21.328 6.55669 20.6361 6.47836 19.7613C6.47836 19.7091 6.4653 19.6569 6.4653 19.6177C6.4653 17.0066 6.45224 14.4086 6.47836 11.7975C6.49141 10.5963 7.57502 9.79995 8.75002 10.0611C9.57252 10.2438 10.1339 10.975 10.147 11.9019C10.16 12.4241 10.147 12.9594 10.147 13.5338Z" fill="#01E769"/>
<path d="M124.709 8.41606C129.879 8.403 133.705 11.7844 134.305 16.8238C134.553 18.8736 134.358 20.8841 133.431 22.7772C132.021 25.6494 129.671 27.2552 126.55 27.7775C124.67 28.0908 122.816 27.9211 121.041 27.2161C118.56 26.2369 116.798 24.5136 115.923 21.9938C114.853 18.9127 114.983 15.8577 116.615 12.9986C118.195 10.2047 120.714 8.80773 123.861 8.46828C124.187 8.44217 124.514 8.42911 124.709 8.41606ZM124.827 26.1063C125.258 26.0672 125.676 26.0541 126.093 25.9888C128.509 25.6233 130.402 24.4613 131.59 22.2941C132.569 20.5186 132.778 18.5994 132.425 16.628C131.916 13.7558 130.363 11.6538 127.582 10.6747C124.775 9.68245 122.111 10.0872 119.788 12.0194C116.954 14.3824 116.471 18.6777 117.79 21.6674C119.122 24.6963 121.628 25.9758 124.827 26.1063Z" fill="#FEFEFE"/>
<path d="M34.6653 27.6338C33.1247 27.6338 31.6103 27.6338 30.0697 27.6338C30.0697 21.3411 30.0697 15.0613 30.0697 8.7555C31.6103 8.7555 33.1247 8.7555 34.6653 8.7555C34.6653 15.0352 34.6653 21.3149 34.6653 27.6338Z" fill="#FEFEFE"/>
<path d="M22.5236 15.3747C22.5236 18.8344 22.5236 22.2811 22.5236 25.7408C22.5236 27.0594 21.3617 27.9341 20.1344 27.5555C19.3772 27.3205 18.855 26.6286 18.855 25.8191C18.8419 24.6702 18.855 23.5213 18.855 22.3725C18.855 16.5758 18.855 10.7661 18.855 4.96939C18.855 3.65078 20.0169 2.77606 21.2442 3.15467C22.0405 3.40273 22.5236 4.09467 22.5236 5.00856C22.5236 8.38995 22.5236 11.7713 22.5236 15.1527C22.5236 15.2311 22.5236 15.3094 22.5236 15.3747Z" fill="#01E769"/>
<path d="M102.006 0.92217C102.619 0.92217 103.207 0.92217 103.821 0.92217C103.821 9.82606 103.821 18.7169 103.821 27.6338C103.22 27.6338 102.632 27.6338 102.006 27.6338C102.006 18.7299 102.006 9.85217 102.006 0.92217Z" fill="#FEFEFE"/>
<path d="M110.923 27.6338C110.309 27.6338 109.709 27.6338 109.095 27.6338C109.095 18.7299 109.095 9.85217 109.095 0.935224C109.696 0.935224 110.296 0.935224 110.923 0.935224C110.923 9.82606 110.923 18.7038 110.923 27.6338Z" fill="#FEFEFE"/>
<path d="M94.4858 2.51495C94.4858 3.41578 94.4858 4.30356 94.4858 5.21745C90.8172 5.21745 87.1747 5.21745 83.493 5.21745C83.493 4.31661 83.493 3.42884 83.493 2.51495C87.1486 2.51495 90.8042 2.51495 94.4858 2.51495Z" fill="#FEFEFE"/>
<path d="M0.590271 15.5966C0.590271 15.1136 0.590271 14.6305 0.590271 14.1344C0.590271 13.1291 1.3736 12.3327 2.37888 12.3066C3.35805 12.2805 4.20666 13.0377 4.24583 14.0169C4.28499 15.0744 4.28499 16.1319 4.24583 17.1894C4.20666 18.1947 3.35805 18.9258 2.33972 18.8997C1.33444 18.8605 0.590271 18.0902 0.590271 17.0849C0.590271 16.5888 0.590271 16.0927 0.590271 15.5966Z" fill="#01E769"/>
</svg>
</div>
                    <p class="text-white mt-2">{{ $t("appFooter.direitos-reservados") }}</p>
                </div>

                <div class="flex-1">
                    <div class="flex items-center justify-center gap-8">
                        <a class="text-2xl " href="https://www.facebook.com/ichelloarts/" target="_blank">
                           
                            <i style="border-radius: 60px;" class="text-button fab fa-facebook on fa-circle-thin "></i>	
                         
                        </a>
                         <a class="text-2xl" href="https://twitter.com/Ichelloarts" target="_blank">
                            <i class="fab fa-twitter text-button"></i>					
                        </a>
                        <a class="text-2xl" href="https://www.instagram.com/ichelloarts/" target="_blank">
                            <i class="fab fa-instagram text-button"></i>					
                        </a>
                       
                        <a class="text-2xl" href="https://t.me/Ichello_tokens" target="_blank">
                            <i class="fab fa-telegram text-button"></i>					
                        </a>
                    </div>
                </div>

                <div class="flex-1 flex flex-col items-center md:items-end">
                    <a class="mb-2" target="_blank" href="https://ichello.com.br/politica-de-privacidade/">{{ $t("appFooter.politica-privacidade") }}</a>
                    <router-link class="mb-2" to="/terms-of-use">{{ $t("appFooter.termos-uso") }}</router-link>
                    <a class="mb-2" target="_blank" href="https://ichello.com.br/politica-de-cookies/">{{ $t("appFooter.politica-cookies") }}</a>
                    <a class="wpp" href="https://wa.me/553190783131?text=Ol%C3%A1" target="_blank">
                        <div class="flex flex-row text-text_button items-center gap-x-2 justify-between">
                            <img :src="require('@/assets/wpp.png')" alt="" class="block mx-auto" width="45">
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data()  {
        return {
            mySVG: require('../../assets/int.svg'),

        };
    },
};
</script>
<style scoped>
.footers { 
    padding: 0 1rem;
}

@media screen and (max-width: 1024px) {
    .footerImage {
        position: relative;
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
        
        flex-direction: column;
    }
    .footerImage img {
        
             position: absolute;
  left: 50%;

  
  transform: translateX(-50%);


}
.footerImage p {
    margin-top: 6rem;
}
}
.wpp {
    position: fixed;
    bottom: 15px;
    right: 15px;
}
</style>
import api from '@/api'

const state = {
    assets: [],
    creators: [], 
    currentCollection: {},
}

const getters = {
    getAssets: state => state.assets,
    getCreators: state => state.creators,
    getCurrentCollection: state => state.currentCollection,
}

const actions = {
    async fetchAssets({ commit }) {
        const response = await api.get('assets')
        commit('SET_ASSETS', response.data)
    },
    async fetchCreators({ commit }) {
        const response = await api.get('creators')
        commit('SET_CREATORS', response.data)
    },
}

const mutations = {
    SET_ASSETS: (state, assets) => (state.assets = assets),
    SET_CREATORS: (state, creators) => (state.creators = creators),
    SET_CURRENT_COLLECTION: (state, collection) => (state.currentCollection = collection),
}
        
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
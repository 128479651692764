export default {
  "appHeader": {
    "cadastrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIN/REGISTER"])},
    "registro-musicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music Register"])},
    "ingressos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickets"])},
    "conectar-carteira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet Connect"])},
    "colecionaveis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Collections"])},
    "minhas-musicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Musics"])},
    "sair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])}
  },
  "appFooter": {
    "direitos-reservados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All rights reserved ®"])},
    "politica-privacidade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "termos-uso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
    "politica-cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies Policy"])},
    "ajuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help?"])}
  },
  "banner": {
    "carousel-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the collection"])}
  },
  "cardHighlight": {
    "destaque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HIGHLIGHTS"])},
    "veja-colecao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the collection"])}
  },
  "cardAsset": {
    "esgotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold out"])},
    "preco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["out of"])},
    "disponivel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available"])},
    "disponivel-l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available"])},
    "disponivel-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available"])},
    "compre-agora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy NOW"])},
    "visualizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])}
  },
  "assetsList": {
    "veja-colecao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See the collection"])}
  },
  "collectionDetails": {
    "usuario-nao-encontrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User not found!"])},
    "erro404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error 404"])},
    "sobre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])}
  },
  "assetDetails": {
    "voltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "esgotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sold out"])},
    "disponivel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available:"])},
    "detalhes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "atributos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attributes"])},
    "compradores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyers"])},
    "data-criacao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation Date: "])},
    "proprietario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Owner: "])},
    "sobre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About: "])},
    "ativo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASSET: "])},
    "sem-compradores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no buyers yet."])},
    "carregando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "arquivo-adicional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional File"])},
    "visualizar-arquivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View File"])},
    "preco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "comprar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "ou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
    "pagar-pix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay using PIX (For Brazil ONLY)"])},
    "pagar-cartao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay with Credit Card"])},
    "conectar-carteira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect Wallet"])},
    "lancamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Release on"])},
    "necessario-carteira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Before you BUY it is necessary to connect your wallet."])},
    "precisa-ajuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Need help?"])},
    "taxa-conveniencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For PIX payments, there will be a small convenience fee. PIX is for BRAZIL ONLY!"])},
    "prova-autenticidade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proof of Authenticity"])},
    "ver-ipfs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See IPFS"])}
  },
  "login": {
    "bemVindo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Música Protegida"])},
    "nao-tem-conta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Does not have an account yet?"])},
    "cadastrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "esqueci-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])}
  },
  "signUp": {
    "bemVindo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Música Protegida"])},
    "ja-tem-conta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "nome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])},
    "sobrenome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "usuario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "repetir-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat your password"])},
    "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF (Brazil only)"])},
    "celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "cadastrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register NOW"])}
  },
  "userDetails": {
    "agrupar-compras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group Purchases"])},
    "editar-perfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Profile"])},
    "nao-ha-compras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no purchases to show yet."])}
  },
  "profile": {
    "informacoes-pessoais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal Information"])},
    "primeiro-nome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
    "ultimo-nome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF (Brazil only)"])},
    "celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "sobre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "redes-sociais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
    "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
    "esconder-colecao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide Collection"])},
    "trocar-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "atualizar-perfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Profile"])},
    "fotoPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile picture"])},
    "enviarImagem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Image"])},
    "alterarImagem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Image"])}
  },
  "changePassword": {
    "alterar-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "senha-atual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "nova-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Password"])},
    "repita-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat your new password"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password changed successfully!"])},
    "voltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "campo-vazio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field cannot be empty!"])}
  },
  "forgotPassword": {
    "esqueceu-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
    "resetar-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset Password"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "voltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "aguarde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait..."])}
  },
  "filterNft": {
    "carregando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
    "sem-categorias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no records of this category at the moment!"])}
  },
  "musicAssetFilter": {
    "musica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Music"])},
    "musicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musics"])}
  },
  "assetTicketAddress": {
    "endereco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "chegar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Know how to get"])}
  },
  "assetTicketPayment": {
    "pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paymnet"])}
  },
  "modalCreditCardPayment": {
    "preco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "insira-dados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTER YOUR DATA"])},
    "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF (Brazil only)"])},
    "contato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHONE NUMBER"])},
    "num-credit-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREDIT CARD NUMBER (VISA OR MASTER)"])},
    "nome-titular-cartao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NAME OF CARD HOLDER"])},
    "digite-nome-cartao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the name on the card"])},
    "validade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALIDITY"])},
    "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "confirmar-pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Payment!"])},
    "processando-pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment is being processed!"])},
    "em-instantes-pag-confirmado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In minutes your payment will be confirmed and your ticket will be available in your profile!"])},
    "campo-obrigatorio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in this field!"])}
  }
}
import { createStore } from 'vuex'

import api from '@/api'
import assets from './assets'

const actions = {
  setToken({ commit }, payload) {
    window.localStorage.setItem('token', payload)
    commit('SET_TOKEN', payload)
  },
  setUser({ commit }, payload) {
    commit('SET_USER', payload)
  },
  async fetchUser({ commit }) {
    const { data: user } = await api.get('user')
    commit('SET_USER', user)
  },
  setCreateToken({ commit }, payload) {
    commit('SET_CREATE_TOKEN', payload)
  },
  setWallet({ commit }, payload) {
    commit('SET_WALLET', payload)
    localStorage.setItem('wallet', payload)
  },
  doLogout({ commit }) {
    window.localStorage.clear('token')
    commit('SET_TOKEN', null)
    commit('SET_USER', null)
  }
}

const mutations = {
  SET_TOKEN (state, payload) {
    state.token = payload
  },
  SET_USER (state, payload) {
    state.user = payload
  },
  SET_WALLET (state, payload) {
    state.wallet = payload
  },
  SET_COOKIES (state, payload) {
    state.cookies = payload.cookies
  }
}

const state = {
  token: localStorage.getItem('token'),
  user: {},
  wallet: localStorage.getItem('wallet') != 'false' ? localStorage.getItem('wallet') : false,
  cookies: false,
}

const getters = {
  isLoggedIn: state => !!state.token,
  user: state => state.user,
  wallet: state => state.wallet,
}

const store = createStore({
  actions,
  mutations,
  state,
  getters,
  modules: {
    assets
  }
})

export function useStore() {
  return store
}

export default store

<template>
    <div class="preview d-flex justify-content-center items-center " >
      <!-- div para o botão de menu dropdown -->
      <div @click="openMenu=!openMenu" class="d-flex items-center">
        <img class="file-preview" :src="user.profile_pic" alt="Profile-Picture" v-if="user.profile_pic" >
        <img class="file-preview" :src="require('@/assets/unknown-profile.jpg')" alt="Profile-Picture" v-else>
        <!-- setinha que muda ao clicar -->
        <span class="text-white ml-1" v-if="!openMenu">
          <i class="fa-solid fa-angle-down"></i>
        </span>
        <span class="text-white ml-1" v-else>
          <i class="fa-solid fa-angle-up"></i>
        </span>
      </div>
      <!-- menu suspenso -->
        <div class="menu-suspenso" v-if="openMenu">
          <div class="flex flex-col">
            <router-link @click="onClickMenu" :to="'/profile'" class="p-3 option_on_hover">
              <i class="fa-solid fa-user"></i>
              {{ user.first_name }} {{ user.last_name }}
            </router-link>
            
            <span class="p-3 option_on_hover" @click="sair(); onClickMenu();">
              <i class="fa-sharp fa-solid fa-right-from-bracket"></i>
              {{ $t("appHeader.sair") }}
            </span>
          </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        mobile: null,
        sair: null,
        user: null,
        walletAddress: null,
    },
    data() {
      return {
        openMenu: false,
      }
    },
    methods: {
      onClickMenu() {
        this.$emit('close')
        this.openMenu = !this.openMenu
      },
    }
}
</script>

<style scoped>
.preview {
  background-color: #212529;
  cursor: pointer;
  padding-right: 0.5em;
  border-radius: 20px;
  position: relative;
}

.menu-suspenso {
  position: absolute;
  /* transform: translate(-100px, 6em); */
  top: 2.5em;
  right: 0;
  z-index: 1;
  padding: 1em 1em;
  background: #27262c;
  color: #fff;
  width: 280px;
  border-radius: 20px;
}

.option_on_hover:hover {
  background: #fff;
  color: #212529;
  border-radius: 10px;
}


.file-preview {
  display: inline-block;
  border-radius: 50%;
  height: 2.5em;
  width: 2.5em;
  object-fit: cover;
  border: 3px solid transparent;
}

.file-preview-mobile {
  display: inline-block;
  border-radius: 50%;
  height: 6em;
  width: 6em;
  object-fit: cover;
  border: 3px solid transparent;
}
</style>
<template>
        <!-- <p>{{ $t('main.welcome') }}</p> -->
        <div class="dropdown">
          <button class="dropbtn " @click="showContent">
            <svg viewBox="0 0 24 24" color="#fff" width="24px" xmlns="http://www.w3.org/2000/svg" class="sc-8a800401-0" style="fill: white;"><path d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM18.92 8H15.97C15.65 6.75 15.19 5.55 14.59 4.44C16.43 5.07 17.96 6.35 18.92 8ZM12 4.04C12.83 5.24 13.48 6.57 13.91 8H10.09C10.52 6.57 11.17 5.24 12 4.04ZM4.26 14C4.1 13.36 4 12.69 4 12C4 11.31 4.1 10.64 4.26 10H7.64C7.56 10.66 7.5 11.32 7.5 12C7.5 12.68 7.56 13.34 7.64 14H4.26ZM5.08 16H8.03C8.35 17.25 8.81 18.45 9.41 19.56C7.57 18.93 6.04 17.66 5.08 16ZM8.03 8H5.08C6.04 6.34 7.57 5.07 9.41 4.44C8.81 5.55 8.35 6.75 8.03 8ZM12 19.96C11.17 18.76 10.52 17.43 10.09 16H13.91C13.48 17.43 12.83 18.76 12 19.96ZM14.34 14H9.66C9.57 13.34 9.5 12.68 9.5 12C9.5 11.32 9.57 10.65 9.66 10H14.34C14.43 10.65 14.5 11.32 14.5 12C14.5 12.68 14.43 13.34 14.34 14ZM14.59 19.56C15.19 18.45 15.65 17.25 15.97 16H18.92C17.96 17.65 16.43 18.93 14.59 19.56ZM16.36 14C16.44 13.34 16.5 12.68 16.5 12C16.5 11.32 16.44 10.66 16.36 10H19.74C19.9 10.64 20 11.31 20 12C20 12.69 19.9 13.36 19.74 14H16.36Z"></path></svg>
          </button>
          <div id="lista-dropdown" class="dropdown-content">
                <li><button class="dropdown-item" type="button" @click="switchLocale('ar')">العربية</button></li>
                <li><button class="dropdown-item" type="button" @click="switchLocale('cn')">简体中文</button></li>
                <li><button class="dropdown-item" type="button" @click="switchLocale('en')">English</button></li>
                <li><button class="dropdown-item" type="button" @click="switchLocale('es')">Español</button></li>
                <li><button class="dropdown-item" type="button" @click="switchLocale('br')">Português</button></li>
          </div>
        </div>
</template>

<script>
window.onclick = function(event) {
  if (!event.target.matches('.dropbtn')) {
    var dropdowns = document.getElementsByClassName("dropdown-content");
    var i;
    for (i = 0; i < dropdowns.length; i++) {
      var openDropdown = dropdowns[i];
      if (openDropdown.classList.contains('show')) {
        openDropdown.classList.remove('show');
      }
    }
  }
}

export default {
    name: 'SelectLanguage',
    data() {
        return {
            language: this.$i18n.locale,
        }
    },
    computed: {

    },
    methods: {
        showContent() {
            document.getElementById("lista-dropdown").classList.toggle("show");
        },
        switchLocale(locale) {
            if(this.$i18n.locale !== locale) {
                this.$i18n.locale = locale;
                localStorage.setItem("ichello-language", locale);
                console.log(localStorage.getItem("ichello-language"))
            }
            document.classList.remove('show');
        }
    },
    created() {
        //se o usuario nunca tiver entrado na página, irá abrir em portugues
        if(localStorage.getItem("ichello-language")==null || localStorage.getItem("ichello-language")=='') {
            localStorage.setItem("ichello-language", "br")
        } else {
            //se o usuário já tiver aberto a página e já tiver escolhido um idioma, a página caregará com o idioma previamente selecionado
            if(this.language!=localStorage.getItem("ichello-language")) {
                this.$i18n.locale=localStorage.getItem("ichello-language");
                // console.log(localStorage.getItem("language"))
                // console.log(this.$i18n.locale)
            }
        }
        
    },
}
</script>

<style scoped>

.dropbtn {
  background-color: #212529;
  border-radius: 20px;
  color: white ;
  padding: 0.6em;
  font-size: 1em;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #212529;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content button {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: 0.5s;
}

.dropdown-content button:hover {
    background-color: #f1f1f1;
    color: black;
    }

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #01E769;
}

.show {display: block;}

</style>
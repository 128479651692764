const { VUE_APP_CLIENT_ID, VUE_APP_CLIENT_SECRET, VUE_APP_URL } = process.env;

class API {
  constructor(
    baseUrl,
    headers = {},
    body = {},
    contentType = "application/x-www-form-urlencoded"
  ) {
    this.headers = headers;
    this.contentType = contentType;
    this.baseUrl = new URL(baseUrl);
    this.body = body;
  }

  request(url, method, params, responseType) {
    if (window.localStorage.getItem("token")) {
      this.headers = {
        ...this.headers,
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": this.contentType,
        mimeType: "multipart/form-data",
        "application-id": window.localStorage.getItem("application"),
      };
    } else {
      this.headers = {
        ...this.headers,
        "application-id": window.localStorage.getItem("application") || 1,
      };
    }

    if (url == "register") {
      delete this.headers.Authorization;
      window.localStorage.removeItem("token");
    }

    const data = {
      ...this.body,
      ...params,
    };

    const formBody = this.getFormBody(data);

    const options =
      method == "POST" ||
      method == "PATCH" ||
      method == "DELETE" ||
      method == "PUT"
        ? {
            method,
            headers: this.headers,
            body: formBody,
          }
        : {
            method,
            headers: this.headers,
          };

    return new Promise((resolve, reject) => {
      fetch(this.baseUrl + url, options)
        .then((response) => {
          if (responseType === "blob") {
            return resolve(
              response.blob().then((images) => URL.createObjectURL(images))
            );
          } else if (response) {
            return resolve(
              response.json().then((data) => data));
          } else if (response.status == 422) {
            console.log("errooooo");
          } else {
            return { success: true };
          }
        })
        .catch((response) => {
          reject(response);
        });
    });
  }

  uploadFiles(url, method, params, responseType) {
    if (window.localStorage.getItem("token")) {
      this.headers = {
        ...this.headers,
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
        mimeType: "multipart/form-data",
        "application-id": window.localStorage.getItem("application"),
      };
    } else {
      this.headers = {
        ...this.headers,
        "application-id": window.localStorage.getItem("application") || 1,
      };
    }
  
    const data = {
      ...this.body,
      ...params,
    };
    
    const formBody = this.getFormData(data);
    console.log("🚀 ~ file: api.js ~ line 102 ~ API ~ uploadFiles ~ formBody", formBody)


    const options =
      method == "POST" ||
      method == "PATCH" ||
      method == "DELETE" ||
      method == "PUT"
        ? {
            method,
            headers: this.headers,
            body: formBody,
          }
        : {
            method,
            headers: this.headers,
          };

    return new Promise((resolve, reject) => {
      fetch(this.baseUrl + url, options)
        .then((response) => {
          if (responseType === "blob") {
            return resolve(
              console.log("primeiro"),
              response.blob().then((images) => URL.createObjectURL(images))
            );
          } else if (response) {
            
            return resolve(
              console.log("segundo"),
              response.json().then((data) => data));
          } else if (response.status == 422) {
            console.log("errooooo");
          } else {
            return { success: true };
          }
        })
        .catch((response) => {
          console.log(options),
          reject(response);
        });
    });
  }

  get(url, params = {}, responseType = "json") {
    return this.request(url, "GET", params, responseType);
  }

  delete(url, params = {}) {
    return this.request(url, "DELETE", params);
  }

  post(url, params) {
    return this.request(url, "POST", params);
  }

  patch(url, params) {
    return this.request(url, "PATCH", params);
  }

  put(url, params) {
    return this.request(url, "PUT", params);
  }

  getFormBody(data) {
    if (this.contentType == "application/x-www-form-urlencoded") {
      const formBody = [];
      for (const property in data) {
        let encodedKey = encodeURIComponent(property);
        
        if (typeof data[property] != "object") {
          const encodedValue = encodeURIComponent(data[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        } else if (data[property]) {
          encodedKey = encodedKey + "[]";
          data[property].forEach((value) => {
            formBody.push(encodedKey + "=" + encodeURIComponent(value));
            
          });
        }
      }

      return formBody.join("&");
    } else {
      const form = new FormData();
      for (const key of Object.keys(data)) {
        form.append(key, data[key]);
      }

      return form;
    }
  }

  getFormData(data) {
      const form = new FormData();
      for (const key of Object.keys(data)) {
        form.append(key, data[key]);
      }
      return form;
    }
  }



const oauthHeaders = {
  "Content-Type": "application/x-www-form-urlencoded",
  Accept: "application/json",
};

const oauthBody = {
  grant_type: "password",
  client_id: VUE_APP_CLIENT_ID,
  client_secret: VUE_APP_CLIENT_SECRET,
  scope: "*",
};

const api = new API(VUE_APP_URL + "api/", oauthHeaders);
export const apiForm = new API(VUE_APP_URL + "api/", oauthHeaders, {}); //, 'multipart/form-data'
export const oauth = new API(VUE_APP_URL + "oauth/", oauthHeaders, oauthBody);
export const openAPI = new API(
  VUE_APP_URL + "api/",
  {
    Accept: "application/json",
  },
  {},
  "application/json"
);
export default api;

import Maska from "maska";
import { createApp } from 'vue';
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import App from './App.vue';
import './assets/tailwind.css';
import './index.css';
import router from './router';


import store from './store';
import i18n from './i18n'



createApp(App).use(i18n).use(store).use(store).use(router).use(VuePlyr).use(Toast, {}).use(Maska).mount('#app')


export default {
  "appHeader": {
    "cadastrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دخولتسجيل"])},
    "registro-musicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الموسيقى"])},
    "ingressos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تذاكر"])},
    "conectar-carteira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربط المحفظة"])},
    "colecionaveis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموعاتي"])},
    "minhas-musicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موسيقى بلدي"])},
    "sair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل خروج"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])}
  },
  "appFooter": {
    "direitos-reservados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل الحقوق محفوظة ®"])},
    "politica-privacidade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
    "termos-uso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأحكام والشروط"])},
    "politica-cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتفاقية ملفات تعريف الارتباط"])},
    "ajuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مساعدة؟"])}
  },
  "banner": {
    "carousel-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انظر المجموعة"])}
  },
  "cardHighlight": {
    "destaque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يسلط الضوء"])},
    "veja-colecao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انظر المجموعة"])}
  },
  "cardAsset": {
    "esgotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نفذ"])},
    "preco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بعيدا عن المكان"])},
    "disponivel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوفرة"])},
    "disponivel-l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوفرة"])},
    "disponivel-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوفرة"])},
    "compre-agora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشتري الآن"])},
    "visualizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رأي"])}
  },
  "assetsList": {
    "veja-colecao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انظر المجموعة"])}
  },
  "collectionDetails": {
    "usuario-nao-encontrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستخدم ليس موجود!"])},
    "erro404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ 404"])},
    "sobre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حول"])}
  },
  "assetDetails": {
    "voltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلف"])},
    "esgotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نفذ"])},
    "disponivel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوفرة:"])},
    "detalhes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل"])},
    "atributos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صفات"])},
    "compradores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المشترون"])},
    "data-criacao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الإنشاء: "])},
    "proprietario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صاحب: "])},
    "sobre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حول: "])},
    "ativo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أصل: "])},
    "sem-compradores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد مشترين بعد."])},
    "carregando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جار التحميل..."])},
    "arquivo-adicional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملف إضافي"])},
    "visualizar-arquivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استعراض الملف"])},
    "preco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر"])},
    "comprar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يشتري"])},
    "ou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أو"])},
    "pagar-pix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع باستخدام PIX (للبرازيل فقط)"])},
    "pagar-cartao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع مع بطاقة الائتمان"])},
    "conectar-carteira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ربط المحفظة"])},
    "lancamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الافراج عن"])},
    "necessario-carteira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قبل الشراء ، من الضروري توصيل محفظتك."])},
    "precisa-ajuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحتاج مساعدة؟"])},
    "taxa-conveniencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالنسبة لمدفوعات PIX ، ستكون هناك رسوم راحة صغيرة. PIX للبرازيل فقط!"])},
    "prova-autenticidade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إثبات الأصالة"])},
    "ver-ipfs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انظر IPFS"])}
  },
  "login": {
    "bemVindo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا بكم في Música Protegida"])},
    "nao-tem-conta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك حساب حتى الآن؟"])},
    "cadastrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يسجل"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "esqueci-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل نسيت كلمة السر"])}
  },
  "signUp": {
    "bemVindo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبا بكم في Música Protegida"])},
    "ja-tem-conta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك حساب؟"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
    "nome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الاول"])},
    "sobrenome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللقب"])},
    "usuario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
    "senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "repetir-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعد كلمة السر الخاصة بك"])},
    "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF (البرازيل فقط)"])},
    "celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هاتف"])},
    "cadastrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل الان"])}
  },
  "userDetails": {
    "agrupar-compras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مشتريات المجموعة"])},
    "editar-perfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعديل الملف الشخصي"])},
    "nao-ha-compras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد مشتريات لعرضها حتى الآن."])}
  },
  "profile": {
    "informacoes-pessoais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات شخصية"])},
    "primeiro-nome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الاول"])},
    "ultimo-nome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللقب"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم المستخدم"])},
    "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF (البرازيل فقط)"])},
    "celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هاتف"])},
    "sobre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حول"])},
    "redes-sociais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
    "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
    "esconder-colecao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إخفاء المجموعة"])},
    "trocar-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير كلمة السر"])},
    "atualizar-perfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث الملف"])},
    "fotoPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الصوره الشخصيه"])},
    "enviarImagem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال صورة"])},
    "alterarImagem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير الصورة"])}
  },
  "changePassword": {
    "alterar-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير كلمة السر"])},
    "senha-atual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور الحالية"])},
    "nova-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة السر الجديدة"])},
    "repita-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كرر كلمة مرورك الجديدة"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم تغيير الرقم السري بنجاح!"])},
    "voltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلف"])},
    "campo-vazio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا المجال لا يمكن أن يكون فارغا!"])}
  },
  "forgotPassword": {
    "esqueceu-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل نسيت كلمة السر؟"])},
    "resetar-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إعادة تعيين كلمة المرور"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
    "voltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلف"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
    "aguarde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أرجو الإنتظار..."])}
  },
  "filterNft": {
    "carregando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جار التحميل..."])},
    "sem-categorias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد سجلات لهذه الفئة في الوقت الحالي!"])}
  },
  "musicAssetFilter": {
    "musica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أغنية"])},
    "musicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأغاني"])}
  },
  "assetTicketAddress": {
    "endereco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تبوك"])},
    "chegar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف تحصل على"])}
  },
  "assetTicketPayment": {
    "pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع"])}
  },
  "modalCreditCardPayment": {
    "preco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سعر"])},
    "insira-dados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بياناتك"])},
    "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF (البرازيل فقط)"])},
    "contato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل"])},
    "num-credit-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رقم البطاقة (فيزا أو ماستر)"])},
    "nome-titular-cartao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم حامل البطاقة"])},
    "digite-nome-cartao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل الاسم على البطاقة"])},
    "validade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صلاحية"])},
    "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع"])},
    "confirmar-pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد الدفع!"])},
    "processando-pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جاري معالجة الدفع!"])},
    "em-instantes-pag-confirmado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["في غضون دقائق ، سيتم تأكيد الدفع الخاص بك وستكون تذكرتك متاحة في ملف التعريف الخاص بك!"])},
    "campo-obrigatorio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى ملء هذا الحقل!"])}
  }
}
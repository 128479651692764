export default {
  "appHeader": {
    "cadastrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIAR SESIÓN/REGISTRAR-SE"])},
    "registro-musicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro de Música"])},
    "ingressos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entradas"])},
    "conectar-carteira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar billetera"])},
    "colecionaveis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis colecciones"])},
    "minhas-musicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis musicas"])},
    "sair": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar Sesión"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])}
  },
  "appFooter": {
    "direitos-reservados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reservados todos los derechos ®"])},
    "politica-privacidade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
    "termos-uso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y condiciones"])},
    "politica-cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de cookies"])},
    "ajuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ayuda?"])}
  },
  "banner": {
    "carousel-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver la colección"])}
  },
  "cardHighlight": {
    "destaque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DESTACAR"])},
    "veja-colecao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver la colección"])}
  },
  "cardAsset": {
    "esgotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendido"])},
    "preco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["precio"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fuera de"])},
    "disponivel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible"])},
    "disponivel-l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible"])},
    "disponivel-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible"])},
    "compre-agora": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar AHORA"])},
    "visualizar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para ver"])}
  },
  "assetsList": {
    "veja-colecao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver la colección"])}
  },
  "collectionDetails": {
    "usuario-nao-encontrado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Usuario no encontrado!"])},
    "erro404": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error 404"])},
    "sobre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre"])}
  },
  "assetDetails": {
    "voltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regresar"])},
    "esgotado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendido"])},
    "disponivel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible:"])},
    "detalhes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
    "atributos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atributos"])},
    "compradores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compradores"])},
    "data-criacao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación: "])},
    "proprietario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dueño: "])},
    "sobre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre: "])},
    "ativo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACTIVO: "])},
    "sem-compradores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aún no hay compradores."])},
    "carregando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando..."])},
    "arquivo-adicional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo adicional"])},
    "visualizar-arquivo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver archivo"])},
    "preco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
    "comprar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "ou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o"])},
    "pagar-pix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pague usando PIX (SOLO para Brasil)"])},
    "pagar-cartao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga con tarjeta de crédito"])},
    "conectar-carteira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conectar billetera"])},
    "lancamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suelte el"])},
    "necessario-carteira": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antes de COMPRAR es necesario conectar su monedero."])},
    "precisa-ajuda": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Necesitas ayuda?"])},
    "taxa-conveniencia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para los pagos de PIX, habrá una pequeña tarifa de conveniencia. PIX es para BRASIL SOLAMENTE!"])},
    "prova-autenticidade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prueba de autenticidad"])},
    "ver-ipfs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver IPFS"])}
  },
  "login": {
    "bemVindo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido a Música Protegida"])},
    "nao-tem-conta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Aún no tienes una cuenta?"])},
    "cadastrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso"])},
    "esqueci-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvidé la contraseña"])}
  },
  "signUp": {
    "bemVindo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido a Música Protegida"])},
    "ja-tem-conta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ya tienes una cuenta?"])},
    "entrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso"])},
    "nome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer nombre"])},
    "sobrenome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "usuario": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de usuario"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "repetir-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repita su contraseña"])},
    "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF (solo Brasil)"])},
    "celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "cadastrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrate AHORA"])}
  },
  "userDetails": {
    "agrupar-compras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compras grupales"])},
    "editar-perfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar perfil"])},
    "nao-ha-compras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay compras para mostrar todavía."])}
  },
  "profile": {
    "informacoes-pessoais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacion personal"])},
    "primeiro-nome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer nombre"])},
    "ultimo-nome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de usuario"])},
    "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF (solo Brasil)"])},
    "celular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "sobre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre"])},
    "redes-sociais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social Media"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
    "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
    "instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
    "esconder-colecao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar colección"])},
    "trocar-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia la contraseña"])},
    "atualizar-perfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualización del perfil"])},
    "fotoPerfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto de perfil"])},
    "enviarImagem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar Imagen"])},
    "alterarImagem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar Imagen"])}
  },
  "changePassword": {
    "alterar-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia la contraseña"])},
    "senha-atual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña actual"])},
    "nova-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
    "repita-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repite tu nueva contraseña"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Contraseña cambiada con éxito!"])},
    "voltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regresar"])},
    "campo-vazio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Este campo no puede estar vacío!"])}
  },
  "forgotPassword": {
    "esqueceu-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Has olvidado tu contraseña?"])},
    "resetar-senha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restablecer la contraseña"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "voltar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regresar"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "aguarde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espere por favor..."])}
  },
  "filterNft": {
    "carregando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando..."])},
    "sem-categorias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡No hay registros de esta categoría en este momento!"])}
  },
  "musicAssetFilter": {
    "musica": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Música"])},
    "musicas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Músicas"])}
  },
  "assetTicketAddress": {
    "endereco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "chegar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saber Cómo Llegar"])}
  },
  "assetTicketPayment": {
    "pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago"])}
  },
  "modalCreditCardPayment": {
    "preco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
    "insira-dados": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INSERTA TUS DATOS"])},
    "cpf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPF (solo Brasil)"])},
    "contato": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO DE TELÉFONO"])},
    "num-credit-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NÚMERO DE TARJETA (VISA O MASTER)"])},
    "nome-titular-cartao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NOMBRE DEL TITULAR DE LA TARJETA"])},
    "digite-nome-cartao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el nombre en la tarjeta"])},
    "validade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VALIDEZ"])},
    "cvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CVV"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "confirmar-pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Confirmar pago!"])},
    "processando-pagamento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡El pago está siendo procesado!"])},
    "em-instantes-pag-confirmado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡En minutos tu pago será confirmado y tu boleto estará disponible en tu perfil!"])},
    "campo-obrigatorio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Por favor complete este campo!"])}
  }
}
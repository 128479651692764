<template>
    <div class="w-full absolute top-20 py-3 z-50 " style="background-color: #27262c;">
        <div v-if="!user" class="flex  flex-column justify-center gap-y-4 px-3">
            <!-- <search-box /> -->
            <!-- <router-link  @click="menuActive = false" to="/login" class="inline-block header-link">MERCADO</router-link>
            <router-link @click="menuActive = false" to="/login" class="inline-block header-link">RESGATAR</router-link>
            <router-link @click="menuActive = false" to="/login" class="inline-block header-link">SOBRE</router-link>
            <router-link @click="menuActive = false" to="/login" class="inline-block header-link">AJUDA</router-link> -->
            <router-link @click="menuActive = false" to="/login" class="inline-block header-link">{{ $t("appHeader.cadastrar") }}</router-link>
            <!-- <router-link @click="menuActive = false" to="/login" class="inline-block px-3 py-2 bg-button rounded-4xl text-text_button font-bold hover:bg-transparent border-2 border-solid border-button hover:text-button">Conectar Carteira</router-link>  -->
        </div>
         <div v-else class="flex flex-column justify-center gap-y-4 px-3">
            <!-- <search-box /> -->
            
          <!--   <router-link  @click="menuActive = false" to="/login" class="inline-block header-link">MERCADO</router-link>
            <router-link @click="menuActive = false" to="/login" class="inline-block header-link">RESGATAR</router-link>
            <router-link @click="menuActive = false" to="/login" class="inline-block header-link">SOBRE</router-link>
            <router-link @click="menuActive = false" to="/login" class="inline-block header-link">AJUDA</router-link> --> 
            
            <router-link @click="closeMenu" :to="'/profile'" class="flex justify-center"> 
                <img class="file-preview-mobile" :src="user.profile_pic" alt="Profile-Picture" v-if="user.profile_pic">
                <img class="file-preview-mobile" :src="require('@/assets/unknown-profile.jpg')" alt="Profile-Picture" v-else>
            </router-link>
            <router-link @click="closeMenu" :to="`/collection/${user.username}`" class="d-flex justify-content-center header-link">
                <i class="fa-solid fa-user mr-1"></i>
                {{ user.first_name }} {{ user.last_name }}
            </router-link>
            <router-link @click="closeMenu" :to="`/my-musics`" class="inline-block header-link flex justify-center">
                <i class="fa-solid fa-right-from-bracket fa-music"></i>
                {{ $t("appHeader.minhas-musicas") }}
            </router-link>
            <button @click="eventLogout" class="inline-block header-link" >
                <i class="fa-sharp fa-solid fa-right-from-bracket "></i>
                {{ $t("appHeader.sair") }}
            </button>
            <!-- <router-link @click="menuActive = false" to="/login" class="inline-block px-3 py-2 bg-button rounded-4xl text-text_button font-bold hover:bg-transparent border-2 border-solid border-button hover:text-button">Conectar Carteira</router-link>  -->
        </div>
    </div>
</template>

<script>
// import SearchBox from '../shared/SearchBox.vue'

export default {
name: 'Mobile Header', 
props: { 
    user: {
        required: 'true'
    },
    walletAddress: null,
},
data() { 
return{ 
    walletLoginService: 'walletconnect',
    renderLoginService: typeof window.ethereum !== 'undefined',
    showLoginService: false,
    showModalLogin: false,
}

},
methods: {
    eventLogout() { 
        this.$emit('close')
        this.$emit("logout", true)
    }, 
    closeMenu() {
        this.menuActive = false
        this.$emit('close')
    },
    openWalletModal() {
        this.menuActive = false
        this.$emit('close')
        this.$emit('conectarCarteira')
    }
},
}
</script>
<style scoped>
.header-link {
    text-transform: uppercase;
    color: #fff;
} 

.file-preview-mobile {
  display: inline-block;
  border-radius: 50%;
  height: 6em;
  width: 6em;
  object-fit: cover;
  border: 3px solid transparent;
}
</style>

import { createRouter, createWebHistory } from 'vue-router'
import api from '@/api'
import store from '@/store'

const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: { guest: true },
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: { guest: true },
    component: () => import('@/views/ForgotPassword/ForgotPassword.vue')
  },
  
  {
    path: '/user/reset-password/:token',
    name: 'ResetPassword',
    meta: { guest: true },
    component: () => import('@/views/ResetPassword.vue')
  },
  {
    path: '/verify-email/:token',
    name: 'VerifyEmail',
    meta: { guest: true },
    component: () => import('@/views/VerifyEmail/VerifyEmail.vue')
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    meta: { guest: true },
    component: () => import('@/views/SignUp/SignUp.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('@/views/Profile.vue')
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    meta: { guest: true },
    component: () => import('@/views/TermsOfUse.vue'),
  },  
  {
    path: '/',
    name: 'Home',
    meta: { guest: true },
    component: () => import('../views/RegistroMusica/RegistroMusicas.vue'),
  },
  {
    path: '/nova',
    name: 'RegistrarMusica',
    component: () => import('../views/RegistroMusica/Cadastrar/RegistrarMusica.vue'),
  },
  {
    path: '/my-musics',
    name: 'MyMusics',
    component: () => import('../views/RegistroMusica/ListaMusicas/MyMusics.vue'),
  },
  {
    path: '/my-musics/details/:id',
    name: 'Music Details',
    component: () => import('../views/RegistroMusica/MusicDetails/MusicDetails.vue'),
  },
  {
    path: '/certificado/:id',
    name: 'Certificado',
    meta: { guest: true },
    component: () => import('../views/RegistroMusica/Certificate/Certificate.vue'),
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token')

  if (to.meta.guest) { next() } else if (!token) {
    next({ name: 'Login' })
  } else if (token) {
    if (store.state.user.id) {
      next()
    } else {
      api.get('user').then(res => {
        if (res.message) {
          next({ name: 'Login' })
        } else {
          store.dispatch('setUser', res.data)
          next()
        }
      })
    }
  }
})

export default router
